import { NgOptimizedImage } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'fusable-header-logo',
  imports: [NgOptimizedImage],
  templateUrl: './header-logo.component.html',
  styleUrl: './header-logo.component.scss',
})
export class HeaderLogoComponent {
  imageSource = input<string>('images/logos/logo.svg');
  width = input<number>(180);
  height = input<number>(62);

  private readonly router = inject(Router);

  navigateToHome(): void {
    this.router.navigate(['/home']);
  }
}
