<div class="d-flex align-items-center pe-2">
  <div class="pe-2 text-right">
    <p class="mb-0 mt-2">{{ info()?.name }}</p>
    <p class="mb-0"><strong>{{ info()?.role }}</strong></p>
  </div>
  <div>
    @if(info()?.name){
    <div class="user-icon">
      {{ info()?.name?.charAt(0) }}
    </div>
    } @else {
    <img ngSrc="images/icons/user.svg" alt="User" height="50" width="50" priority="low" />
    }
  </div>
  <img ngSrc="images/icons/down-arrow-black.svg" class="mx-2 cursor-pointer" alt="arrow" height="24" width="24"
    priority="low" [matMenuTriggerFor]="menu" />
  <mat-menu #menu="matMenu" class="menu-primary">
    <div class="d-flex align-items-center justify-content-center cursor-pointer" (click)="handleLogoutClick()"
      (keyPress)="handleLogoutClick()">
      <img ngSrc="images/icons/logout-half-circle-line.svg" alt="logout" height="24" width="24" priority="low" />
      <a class="ms-2">Log Out</a>
    </div>
  </mat-menu>
</div>