import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { IResetPasswordLink } from '../models/reset-password-link.model';
import { ILoginUser } from '../models/login-user.model';
import { ICommonResponse } from '../models/common-response.model';
import { ITokenResponse } from '../models/token-response.model';
import { IResetPassword } from '../models/reset-password.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly apiBaseUrl = environment.FusableUiApi;
  private readonly httpClient = inject(HttpClient);
  public redirectUrl: string | null = null;

  private key: any; // Must be 16, 24, or 32 bytes for AES-128, AES-192, or AES-256
  private iv: any; // Must be 16 bytes for AES

  constructor() {
    // Define your key and IV (these should be securely stored)
    this.key = CryptoJS.enc.Utf8.parse('b4a0743f32f0dec1'); // 16 bytes key
    this.iv = CryptoJS.enc.Utf8.parse('b4a0743f32f0dec1'); // 16 bytes IV
  }

  encrypt(data: string): string {
    const encrypted = CryptoJS.AES.encrypt(data, this.key, {
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString(); // Cipher text
  }

  loginuser(login: ILoginUser): Observable<ICommonResponse<ITokenResponse>> {
    console.log(login);
    login.password = this.encrypt(login.password);
    // login.password = CryptoJS.AES.encrypt(login.password, 'b4a0743f32f0dec1').toString();
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.httpClient.post<ICommonResponse<ITokenResponse>>(
      this.apiBaseUrl + '/api/v1/auth/login',
      login,
      httpOptions
    );
  }

  resetpassword(resetpassword: IResetPassword): Observable<ICommonResponse<object>> {
    // resetpassword.newPassword = this.encrypt(resetpassword.newPassword);
    console.log(resetpassword);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.httpClient.post<ICommonResponse<object>>(
      this.apiBaseUrl + '/api/v1/auth/reset-password',
      resetpassword,
      httpOptions
    );
  }

  validateResetPasswordLink(resetPasswordLink: IResetPasswordLink): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.httpClient.post<any>(
      this.apiBaseUrl + '/api/v1/auth/token-validation',
      resetPasswordLink,
      httpOptions
    );
  }

  forgotPassword(email: string): Observable<any> {
    return this.httpClient.post<any>(
      this.apiBaseUrl + '/api/v1/auth/forgot-password',
      { email }
    );
  }

  // login(user: ILoginUser): Observable<any> {
  //   console.log(user);
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //   });
  //   return this.httpClient.post('http://localhost:3000/login', user, {
  //     headers,
  //   });
  // }

  isLoggedIn(): boolean {
    const token = localStorage.getItem('token');
    if (token) {
      return true;
    }
    return false;
  }
}
