import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { of } from 'rxjs';

export const authGuard: CanActivateFn = (_, state) => {
  let authService = inject(AuthService);
  let router = inject(Router);
  if (authService.isLoggedIn()) {
    console.log('Authorized');
    return of(true);
  } else {
    console.log('Not authorized');
    authService.redirectUrl = state.url;
    router.navigate(['/auth']);
    return of(false);
  }
};
