<div class="page-container">
  <div class="content">
    <div class="p-3">
      <fusable-header-logo></fusable-header-logo>
    </div>
    <div>
      <ul class="nav-items p-0">
        @for (item of navItems; track item.label) {
        <li
          routerLinkActive="active-link"
          [routerLink]="item.routerLink"
          [class.active-link]="isChildActive(item)"
          (click)="toggleExpand(item)"
        >
          <span class="d-flex-between">
            <div>
              <img
                [ngSrc]="'images/icons/' + item.icon"
                [alt]="item.label"
                width="24"
                height="24"
                priority="low"
              />
              <span>{{ item.label }}</span>
            </div>
            @if (item.children && item.children.length > 0) {
            <img
              [ngSrc]="
                item.isExpanded
                  ? 'images/icons/chevron-up.svg'
                  : 'images/icons/chevron-down.svg'
              "
              alt="arrow"
              width="24"
              height="24"
              priority="low"
              class="fuse-icon-black"
            />
            }
          </span>
        </li>
        @if (item.children && item.children.length > 0 && item.isExpanded) {
        <ul class="nav-items">
          @for (innerItem of item.children; track innerItem.label) {
          <li
            routerLinkActive="sub-active-link"
            class="py-2"
            [routerLink]="innerItem.routerLink"
          >
            <span>{{ innerItem.label }}</span>
          </li>
          }
        </ul>
        } }
      </ul>
    </div>
  </div>

  <footer class="footer">
    <div class="d-flex-center">
      <img
        ngSrc="images/icons/copyright.svg"
        width="22"
        height="24"
        alt="Copyright"
      />
      <span class="ms-1"
        >{{ currentYear }} Fusable <sup><small>TM</small></sup></span
      >
    </div>
  </footer>
</div>
