import { Component, inject, OnInit } from '@angular/core';
import { ICurrentUser } from '../../models/current-user.model';
import { UserInfoPreviewComponent } from '../user-info-preview/user-info-preview.component';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'fusable-header',
  imports: [
    UserInfoPreviewComponent
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {

  userInfo: ICurrentUser | null = null;
  private readonly userService = inject(UserService);

  ngOnInit(): void {
    this.userInfo = this.userService.getCurrentUser();
  }
}
