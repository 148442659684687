import { Component, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogActions, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { IPromptLogoutConfirmationDialogOutput } from '../../../models/prompt-logout-confirmation-dialog-output.model';
import { DialogAction } from '../../../enums/dialog-action.enum';

@Component({
  selector: 'fusable-prompt-logout-confirmation',
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatButton
  ],
  templateUrl: './prompt-logout-confirmation.component.html',
  styleUrl: './prompt-logout-confirmation.component.scss'
})
export class PromptLogoutConfirmationComponent {

  readonly dialogRef = inject(MatDialogRef<PromptLogoutConfirmationComponent>);

  handleYesClick(): void {
    const data: IPromptLogoutConfirmationDialogOutput = {
      dialogAction: DialogAction.YES
    };
    this.dialogRef.close({ data });
  }

  handleNoClick(): void {
    const data: IPromptLogoutConfirmationDialogOutput = {
      dialogAction: DialogAction.NO
    };
    this.dialogRef.close({ data });
  }
}
