import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getCustomPaginatorIntl } from './shared/intls/custom-paginator-intl';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { authInterceptor } from './interceptors/auth.interceptor';
import { loaderInterceptor } from './interceptors/loader.interceptor';
import { inspectApiResponseInterceptor } from './interceptors/inspect-api-response.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(
      withInterceptors([
        authInterceptor,
        loaderInterceptor,
        inspectApiResponseInterceptor
      ]),
      withInterceptorsFromDi(),
    ),
    provideRouter(routes,  withComponentInputBinding()),
    provideAnimationsAsync(),
    {
      provide: MatPaginatorIntl,
      useValue: getCustomPaginatorIntl(),
    }, provideCharts(withDefaultRegisterables()),
  ],
};
