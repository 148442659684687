import { Injectable } from '@angular/core';
import { INavItem } from '../models/nav-item.model';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  getNavItems(): INavItem[] {
    return [
      {
        label: 'Home',
        icon: 'home.svg',
        routerLink: 'home',
      },
      {
        label: 'Data Stewardship',
        icon: 'entity-stewardship.svg',
        routerLink: 'data-stewardship',
      },
      {
        label: 'Search',
        icon: 'search.svg',
        routerLink: 'search',
      },
      {
        label: 'Fulfillment',
        icon: 'match.svg',
        isExpanded: false,
        children: [{ label: 'Bulk Match', routerLink: 'bulk-match' }],
      },
      {
        label: 'Reports',
        icon: 'reports.svg',
        routerLink: 'reports',
      },
      {
        label: 'Settings',
        icon: 'settings.svg',
        isExpanded: false,
        children: [
          {
            label: 'Security',
            routerLink: 'settings/security',
          },
        ],
      },
      {
        label: 'About Us',
        icon: 'about-us.svg',
        routerLink: 'about-us',
      }
    ];
  }
}
