import { Component, inject, input } from '@angular/core';
import { ICurrentUser } from '../../models/current-user.model';
import { NgOptimizedImage } from '@angular/common';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PromptLogoutConfirmationComponent } from './prompt-logout-confirmation/prompt-logout-confirmation.component';
import { IPromptLogoutConfirmationDialogOutput } from '../../models/prompt-logout-confirmation-dialog-output.model';
import { DialogAction } from '../../enums/dialog-action.enum';

@Component({
  selector: 'fusable-user-info-preview',
  imports: [
    NgOptimizedImage,
    MatMenu,
    MatMenuTrigger
  ],
  templateUrl: './user-info-preview.component.html',
  styleUrl: './user-info-preview.component.scss',
})
export class UserInfoPreviewComponent {
  info = input.required<ICurrentUser | null>();

  router = inject(Router);
  private readonly dialog = inject(MatDialog);

  handleLogoutClick(): void {
    const dialogRef = this.dialog.open(PromptLogoutConfirmationComponent, {
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const { data }: { data: IPromptLogoutConfirmationDialogOutput } = result;
        if (data.dialogAction === DialogAction.YES) {
          localStorage.removeItem('token');
          this.router.navigate(['redirect']);
        }
      }
    });
  }
}
