export function getUiApiAuthToken() {
    const token = localStorage.getItem("token")?.trim();
    if (token) {
        return token;
    }
    return '';
}

export const decodeToken = (token: string) => {
    try {
      const base64Url = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
      return JSON.parse(atob(base64Url));
    } catch (e) {
      return null;
    }
  };
  