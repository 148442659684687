<mat-drawer-container class="layout-container">
    <mat-drawer #sidenav [mode]="isSmallScreen ? 'over' : 'side'" [opened]="!isSmallScreen" class="custom-sidenav">
        <fusable-sidenav></fusable-sidenav>
    </mat-drawer>
    <mat-drawer-content>
        <fusable-header></fusable-header>
        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
<div class="toggleMenu" [ngClass]="{'toggleMenuLeft': !sidenav.opened}">
    <a class="toggleMenuBtn" (click)="sidenav.toggle()">
        <img [ngSrc]="!sidenav.opened ? 'images/icons/right-arrow.svg':'images/icons/left-arrow.svg'" width="24" height="24" alt="arrow" />
    </a>
</div>