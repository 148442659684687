export enum UserStatus {
  Active = 1,
  Locked = 2,
  Deleted = 3
}

export enum UserRole {
  DataSteward = 1,
  Admin = 2,
  Fulfillment = 3
}

