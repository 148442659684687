import { IUserFilter } from './../models/filter-user.model';
import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IUser } from '../models/user.model';
import { getQueryParams } from '../utilities/query-params.util';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ICommonResponse } from '../models/common-response.model';
import { ICommonDataResponse } from '../models/common-data-response.model';
import { ICurrentUser } from '../models/current-user.model';
import { UserRole } from '../enums/user.enum';
import { IAddUser } from '../models/add-user.model';
import { IEditUser } from '../models/edit-user.model';
import { decodeToken } from '../utilities/token.util';
import { USER_ROLE } from '../shared/constants/user-role';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly tokenKey = 'token';
  private readonly CLAIMS = {
    name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
    role: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
  };
  private readonly apiBaseUrl = environment.FusableUiApi;
  private readonly httpClient = inject(HttpClient);

  getCurrentUser(): ICurrentUser | null {
    const token = localStorage.getItem(this.tokenKey);
    if (token) {
      try {
        const decoded = decodeToken(token);
        if (decoded) {
          return {
            name: decoded[this.CLAIMS.name] || '',
            role: decoded[this.CLAIMS.role] || '',
          } as ICurrentUser;
        }
        return null;
      } catch (error) {
        console.error('Error decoding token:', error);
        return null;
      }
    }
    return null;
  }

  getUsers(
    filter: IUserFilter
  ): Observable<ICommonResponse<ICommonDataResponse<IUser>>> {
    const url = `${this.apiBaseUrl}/api/v1/users?` + getQueryParams(filter);
    return this.httpClient.get<ICommonResponse<ICommonDataResponse<IUser>>>(
      url
    );
  }

  addUser(user: Partial<IUser>): Observable<ICommonResponse<object>> {
    const payload: IAddUser = {
      name: user.name,
      email: user.email,
      role: user.role ? UserRole[user.role] : null,
      //role: user.role ? USER_ROLE.find(x => x.roleId === user.role)?.roleName : null,
      status: user.status,
    };
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.httpClient.post<ICommonResponse<object>>(
      this.apiBaseUrl + '/api/v1/users',
      payload,
      httpOptions
    );
  }

  editUser(
    userId: string,
    user: Partial<IUser>
  ): Observable<ICommonResponse<object>> {
    const payload: IEditUser = {
      name: user.name,
      role: user.role ? UserRole[user.role] : null,
      //role: user.role ? USER_ROLE.find(x => x.roleId === user.role)?.roleName : null,
      status: user.status,
    };
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.httpClient.put<ICommonResponse<object>>(
      this.apiBaseUrl + '/api/v1/users/' + userId,
      payload,
      httpOptions
    );
  }
  activateUsers(user: Partial<IUser>): Observable<ICommonResponse<object>> {
    const payload = {
      role:user.role? UserRole[user.role] : null,
      status: user.status,
    };
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.httpClient.post<ICommonResponse<object>>(
      this.apiBaseUrl + '/api/v1/users/' + user.id + '/activate',
      payload,
      httpOptions
    );
  }

  deleteUser(userId: string): Observable<ICommonResponse<object>> {
    return this.httpClient.delete<ICommonResponse<object>>(
      this.apiBaseUrl + '/api/v1/users/' + userId
    );
  }
}
