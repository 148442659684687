import { HttpParams } from "@angular/common/http";

export function getQueryParams<T>(data: T): HttpParams {
    let params = new HttpParams();
    let key: keyof typeof data;
    for (key in data) {
        const value = data[key];
        if (value || (value === 0)) {
            params = params.append(key, value as string | number | boolean);
        }
    }
    return params;
}