import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { LoaderService } from '../services/loader.service';
import { finalize } from 'rxjs';
import { environment } from '../../environments/environment';

export const loaderInterceptor: HttpInterceptorFn = (req, next) => {
  const loaderService = inject(LoaderService);
  const urlsToExclude = [(environment.FusableUiApi + '/api/v1/companies/match/files/upload')];
  const isAutoRefresh = req.headers.get('X-Auto-Refresh') === 'true';

  if (!urlsToExclude.includes(req.url) && !isAutoRefresh) {
    loaderService.ongoingRequests.update(current => ++current);
  }
  
  return next(req).pipe(finalize(() => {
    if (loaderService.ongoingRequests() > 0) {
      loaderService.ongoingRequests.update(current => --current);
    }
  }));
};
