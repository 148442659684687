import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { RedirectComponent } from './shared/components/redirect/redirect.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'auth',
    loadChildren: () => import('./routes/auth.routes').then(r => r.AUTH_ROUTES)
  },
  {
    path: 'redirect',
    component: RedirectComponent
  },
  {
    path: '',
    canActivate: [authGuard],
    children: [
      {
        path: 'home',
        loadComponent: () =>
          import('./components/home/home.component').then((m) => m.HomeComponent),
      },
      {
        path: 'home/process-history',
        loadComponent: () =>
          import(
            './components/home/process-history/process-history.component'
          ).then((m) => m.ProcessHistoryComponent),
      },
      {
        path: 'data-stewardship',
        loadComponent: () =>
          import(
            './components/entity-stewardship/entity-stewardship.component'
          ).then((m) => m.EntityStewardshipComponent),
      },
      {
        path: 'search',
        loadComponent: () =>
          import('./components/search/search.component').then(
            (m) => m.SearchComponent
          ),
      },
      {
        path: 'match',
        loadComponent: () =>
          import('./components/match/match.component').then(
            (m) => m.MatchComponent
          ),
      },
      {
        path: 'bulk-match',
        loadComponent: () =>
          import('./components/bulk-match/bulk-match.component').then(
            (m) => m.BulkMatchComponent
          ),
      },
      {
        path: 'reports',
        loadComponent: () =>
          import('./components/reports/reports.component').then(
            (m) => m.ReportsComponent
          ),
      },
      {
        path: 'settings/security',
        loadComponent: () =>
          import('./components/settings/security/security.component').then(
            (m) => m.SecurityComponent
          ),
      },
      {
        path: 'about-us',
        loadComponent: () =>
          import('./components/about-us/about-us.component').then(
            (m) => m.AboutUsComponent
          ),
      },
      {
        path: '**',
        loadComponent: () =>
          import('./components/page-not-found/page-not-found.component').then(
            (m) => m.PageNotFoundComponent
          ),
      }
    ]
  }
];
